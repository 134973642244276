import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { generateStore } from "./redux/index";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from 'react-redux'
const {store, persistor} = generateStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </PersistGate>
    </Provider>
);

reportWebVitals();
