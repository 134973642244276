import React from "react";

const Footer = () => {
    return (
        <footer>
            <div className="row">
                <div className="col-6 pl-3">
                    <p className="text-bold-600">{new Date().getFullYear()} © INNODE</p>
                </div>
                <div className="col-6 text-right pr-5">
                    <p className="text-bold-600">LEFAR</p>
                </div>
            </div>
        </footer>
    );
};
export default Footer;
